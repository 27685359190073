import Link from 'next/link'
import React from 'react'
import { FavoritesButton } from 'src/features/favorites/components'
import SavedFavoritesCard from 'src/features/favorites/components/SavedFavoritesCard'
import { FavoriteType } from 'src/features/favorites/types/enums/FavoriteType'
import { getNote } from 'src/features/favorites/utils/helpers'
import { CompositeProduct } from 'src/types/schema/graphql'
import FavoriteCardFooter from './FavoriteCardFooter'
import FavoriteCardHeader from './FavoriteCardHeader'

interface IFavoriteProductCard {
    product: CompositeProduct
}

const FavoriteProductCard: React.FC<IFavoriteProductCard> = ({ product }) => {
    const link = {
        href: `/brewer/${product?.brand?.slug}/products/${product?.slug}`,
        label: `Product page for ${product?.title}`,
    }
    const note = getNote(product?.id, FavoriteType.Product)
    return (
        <SavedFavoritesCard item={product} link={link} customClasses='card--saved-item product'>
            <FavoritesButton item={product} type={FavoriteType.Product} />
            <Link href={link.href}>
                <a aria-label={link.label} className='inline-block w-full'>
                    <FavoriteCardHeader item={product} favoriteType={FavoriteType.Product} />
                </a>
            </Link>
            <FavoriteCardFooter favoriteType={FavoriteType.Product} item={product} />
        </SavedFavoritesCard>
    )
}
export default FavoriteProductCard
