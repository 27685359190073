import classNames from 'classnames'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'
import { Footer } from 'src/components/footer'
import { Header } from 'src/components/header'
import DeleteBoardModal from 'src/features/account/components/favorites/DeleteBoardModal'
import LoginGate from 'src/features/auth/components/LoginGate'
import { useLoginGateStore } from 'src/features/auth/state/useLoginGateStore'
import { userStore } from 'src/features/auth/state/userStore'
import { CreateBoard } from 'src/features/favorites/components'
import DeleteAccountModal from 'src/features/account/components/security/DeleteAccountModal'
import FavoritesModal from 'src/features/favorites/components/FavoritesModal'
import { useFavoriteActionsStore } from 'src/features/favorites/state/useFavoriteActionsStore'
import { useSavedBoardsStore } from 'src/features/favorites/state/useSavedBoardsStore'
import DeleteRoute from 'src/features/Map/components/routes/DeleteRoute'
import SaveRoutes from 'src/features/Map/components/routes/SaveRoutes'
import { useSavedRoutesStore } from 'src/features/Map/state/useSavedRoutesStore'
import { gtmPageView } from 'src/features/tracking/utils/gtm'
import Head from 'next/head'

interface IDefaultLayout {
    children: React.ReactNode
}

const DefaultLayout: React.FC<IDefaultLayout> = ({ children }) => {
    const router = useRouter()
    const favoriteModalOpen = useFavoriteActionsStore((state) => state.favoriteModalOpen)
    const savedRouteModalOpen = useSavedRoutesStore((state) => state.savedRouteModalOpen)
    const deleteRouteModalOpen = useSavedRoutesStore((state) => state.deleteRouteModalOpen)
    const loginGateActive = useLoginGateStore((state) => state.loginGateActive)
    const createBoardModalOpen = useSavedBoardsStore((state) => state.createBoardModalOpen)
    const deleteBoardModalOpen = useSavedBoardsStore((state) => state.deleteBoardModalOpen)
    const deleteAccountModalOpen = userStore((state) => state.deleteAccountModalOpen)

    useEffect(() => {
        router.events.on('routeChangeComplete', gtmPageView)
        return () => {
            router.events.off('routeChangeComplete', gtmPageView)
        }
    }, [router.events])

    return (
        <>
            <Head>
                <meta name='apple-itunes-app' content='app-id=6449491682' />
            </Head>
            <div className={classNames(`page-${String(router?.pathname)?.replace(/\//g, '-')}`)}>
                <Header
                    brand={{
                        title: 'BreweryDB',
                        url: '/',
                        asset: '/brewerydb-logo--no-tagline.svg',
                    }}
                />

                <main
                    className={classNames(
                        process.env.NODE_ENV === 'development' && 'debug-screens'
                    )}
                >
                    {children}
                </main>
                <Footer
                    brand={{
                        title: 'BreweryDB',
                        url: '/',
                        asset: '/brewerydb-logo.svg',
                    }}
                />
                {savedRouteModalOpen && <SaveRoutes />}
                {deleteRouteModalOpen && <DeleteRoute />}
                <LoginGate />

                {favoriteModalOpen && <FavoritesModal />}
                {createBoardModalOpen && <CreateBoard />}
                {deleteBoardModalOpen && <DeleteBoardModal />}
                {deleteAccountModalOpen && <DeleteAccountModal />}
            </div>
        </>
    )
}

export default DefaultLayout
