import { useSavedBoardsStore } from '../state/useSavedBoardsStore'
import { IField } from '../types/interfaces/IField'
const { setCreateBoardModalOpen, setNewBoardTitle, handleCreateBoard } =
    useSavedBoardsStore.getState()
interface IAction {
    type: string
    label: string
    onClick: () => void
}
enum CreateNewBoardProperties {
    Fields = 'fields',
    Actions = 'actions',
}
interface ICreateNewBoardConfig {
    [CreateNewBoardProperties.Fields]: IField[]
    [CreateNewBoardProperties.Actions]: {
        cancel: IAction
        create: IAction
    }
}

export const createNewBoardConfig: ICreateNewBoardConfig = {
    fields: [
        {
            label: 'Title',
            name: 'title',
            type: 'text',
            placeholder: 'My New Board',
            onChange: ({ target }) => setNewBoardTitle(target?.value),
        },
    ],
    actions: {
        cancel: {
            type: 'cancel',
            label: 'Cancel',
            onClick: () => setCreateBoardModalOpen(false),
        },
        create: {
            type: 'submit',
            label: 'Create Board',
            onClick: async () => await handleCreateBoard(),
        },
    },
}
