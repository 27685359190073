import { gql } from 'graphql-request'

export const getBoardsByAuthenticatedUserQuery = gql`
query getBoardsByAuthenticatedUser {
    getBoardsByAuthenticatedUser {
       id
      title
      createdDate
      updatedDate
      createdBy
      lastUpdatedBy
      routes {
          id
          createdBy
          isActive
            createdDate
            description
            distance
            duration
            id
            lastUpdatedBy
            notes
            routeId
            title
            updatedDate
            coordinates {
                address {
                    city
                    country
                    geo {
                        lat
                        lon
                        title
                    }
                    postalCode
                    state
                    street
                    street2
                }
                lat
                lon
                placeName
                title
                location {
                    title
                    id
                    slug
                    address {
						city
						country
						geo {
                            lat
                            lon
                            title
                        }
						postalCode
						state
						street
						street2
					}
                }
            }
      }
      title
      favorites {
          favoriteLocations {
              brandId
              locationId
              notes
              isActive
          }
          favoriteProducts {
              brandId
              productId
              isActive
              notes
          }
          favoriteBrands {
                brandId
                isActive
                notes
          }
      }
    }
  }`
