import { gql } from 'graphql-request'

export const removeItemFromBoardMutation = gql`mutation RemoveItemFromBoard($input: inputBoardItem!) {
	removeItemFromBoard(input: $input){
		updatedDate
		title
		lastUpdatedBy
		id
		createdDate
		createdBy
		favorites {
			favoriteBrands {
				brandId
				isActive
				notes
			}
			favoriteLocations {
				locationId
				isActive
				brandId
				notes
			}
			favoriteProducts {
				brandId
				isActive
				notes
				productId
			}
		}
		routes {
			id
		}
	}
}`
