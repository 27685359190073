import {
    InputFavoriteBrand,
    InputFavoriteLocation,
    InputFavoriteProduct,
} from 'src/types/schema/graphql'
import { useFavoriteActionsStore } from '../../state/useFavoriteActionsStore'
import { FavoriteType } from '../../types/enums/FavoriteType'

export const getNote = (id: string, favoriteType: FavoriteType) => {
    const favoriteLocationIds = useFavoriteActionsStore.getState().favoriteLocationIds
    const favoriteProductIds = useFavoriteActionsStore.getState().favoriteProductIds
    const favoriteBrandIds = useFavoriteActionsStore.getState().favoriteBrandIds

    switch (favoriteType) {
        case FavoriteType.Location:
            const favoriteLocation: InputFavoriteLocation = favoriteLocationIds?.find(
                (loc) => loc.locationId === id
            )
            if (favoriteLocation) {
                return favoriteLocation?.notes
            }
            return ''
        case FavoriteType.Product:
            const favoriteProduct: InputFavoriteProduct = favoriteProductIds?.find(
                (product) => product.productId === id
            )
            if (favoriteProduct) {
                return favoriteProduct?.notes
            }
            return ''
        case FavoriteType.Brand:
            const favoriteBrand: InputFavoriteBrand = favoriteBrandIds?.find(
                (brand) => brand.brandId === id
            )
            if (favoriteBrand) {
                return favoriteBrand?.notes
            }
            return ''
    }
}
